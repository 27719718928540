import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'environments/environment';
import { ServiceService } from 'modules/core/services/api/service.service';
import { GlobalStateService } from 'modules/core/services/state/global-state';
import { EncryptedLocalStorageService } from 'modules/core/services/storage';

@Component({
	selector: 'rb-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	providers: [ServiceService],
})
export class NavbarComponent implements OnDestroy {
	public showNavbar: boolean;
	public homeDropdown: boolean;
	public dispatchViewFix: string;
	public previousRoute: string = '';
	public currentRoute: string = '';
	public qParams: object;
	public poNumber: string;
	public totalCount: number = 0;
	public urgency = 'Urgent';
	public status = 'HoldInspection';
	private serviceCountPollingId: NodeJS.Timeout;
	agentName: any;
	agentId: any;
	agentUsersAccess: any;
	agentLogsAccess: any;
	agentDispatchAccess: any;
	agentDashboardAccess: any;
	agentSalesAccess: any;
	agentSubscriptionsAccess: any;

	public get salesLink(): string {
		return `${environment.salesClientLink}/login/${this.storage.secureRetrieve('token')}`;
	}

	constructor(
		public router: Router,
		public storage: EncryptedLocalStorageService,
		public route: ActivatedRoute,
		private sService: ServiceService,
		private globalStateService: GlobalStateService
	) {}

	private stopServiceCountPolling() {
		if (this.serviceCountPollingId) clearInterval(this.serviceCountPollingId);
	}

	public async getServiceCount() {
		const body: any = {
			status: this.status,
			branch: this.globalStateService.getBranch(),
			urgency: this.urgency,
		};
		const response = await this.sService.getServicesCount(body);
		if (response.status) {
			this.totalCount = response.data.total;
		}
	}

	public getAccesses(): void {
		this.agentName = this.storage.retrieve('employeeName');
		this.agentId = this.storage.retrieve('_id');

		this.agentUsersAccess = this.storage.secureRetrieve('agentUsersAccess');
		this.agentLogsAccess = this.storage.secureRetrieve('agentLogsAccess');
		this.agentDispatchAccess = this.storage.secureRetrieve('agentDispatchAccess');
		this.agentDashboardAccess = this.storage.secureRetrieve('agentDashboardAccess');
		this.agentSalesAccess = this.storage.secureRetrieve('agentSalesAccess');
		this.agentSubscriptionsAccess = this.storage.secureRetrieve('agentSubscriptionsAccess');
	}

	public logoutClick(): void {
		this.storage.clear();
		this.router.navigate(['/login']);
	}

	searchService(): void {
		this.router.navigate(['/service', this.poNumber]);
		this.poNumber = '';
	}

	ngOnDestroy(): void {
		this.stopServiceCountPolling();
	}
}

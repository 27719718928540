// eslint-disable-next-line
export const SITUATIONS = {
	LOCKSMITH: 'locksmith',
	FLAT_TIRE: 'flatTire',
	FUEL_DELIVERY: 'fuelDelivery',
	JUMP_START: 'jumpStart',
	BATTERY_DIAGNOSTIC: 'batteryDiagnostic',
	BATTERY_SALE: 'battery_sale',
	TOW_COLLISION: 'towCollision',
	TOW_COLLISSION: 'towCollission',
	TOW_BREAKDOWN: 'towBreakdown',
	TOW_TIRE: 'towTire',
	TOW_EXTRACTION: 'towExtraction',
	INSPECTIONS: 'inspections',
	OTHER_SERVICES: 'situationOtherServices',
	ELECTRICITY: 'electricity',
	PLUMBING: 'plumbing',
	HANDYMAN: 'handyman',
	WHEEL_LIFT: 'wheelLift',
	TOW_HEAVY_WEIGHT: 'towHeavyWeight',
	TAXI_TO_AIRPORT: 'taxi_to_airport',
	GLASSWORKS: 'glassworks',
	HOME_LOCKSMITH: 'home_locksmith',
	VIRTUAL_INSPECTION: 'virtual_inspection',
};

/**
 *  A map of the service type category to the icon name of that category.
 */
export const serviceTypeCategoryToIcon = new Map<string, string>([
	['Electricity', 'electricity'],
	['Plumbing', 'plumbing'],
	['Home Locksmith', 'home_locksmith'],
	['Glassworks', 'glassworks'],
	['Handyman', 'handyman'],
	['Pest Control', 'pest_control'],
	['Fall Prevention', 'fall_prevention'],
	['Tech Assistance', 'tech_assistance'],
]);

export const OTHER_SERVICES_ALLOWED_PR = ['rx', 'glass_replacement', 'injury_or_sickness', 'luggage'];

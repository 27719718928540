import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as io from 'socket.io-client';
import { ActivatedRoute, Router } from '@angular/router';

import { IncomingCallToastComponent } from 'modules/shared/incoming-call-toast/incoming-call-toast.component';
import IAudaraIncomingCall from '../../../../assets/models/interfaces/IAudaraIncomingCall';

import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { isAfterDatesComparison, subtractSeconds } from '../../../shared/util/dates';
import { EncryptedLocalStorageService } from '../storage';

// Time in milliseconds in which the toaster will be visible on the screen
const INCOMING_CALL_TOAST_TIMEOUT = 60000;

// Time in seconds to allow receiving the same call, this is to support the opening of the toaster in all open tabs
const TIME_TO_HANDLE_DUPLICATED_CALLS_SEC = 5;

@Injectable({
	providedIn: 'root',
})
export class IncomingCallSocketService {
	public incomingCallIO: SocketIOClient.Socket;

	private currentCall = new BehaviorSubject<IAudaraIncomingCall | null>(null);
	public currentCallObservable = this.currentCall.asObservable();

	constructor(
		public router: Router,
		public storage: EncryptedLocalStorageService,
		public route: ActivatedRoute,
		public toastr: ToastrService
	) {}

	init() {
		this.incomingCallIO = io(environment.apiLink + '/incoming_call', {
			transports: ['websocket', 'polling'],
			forceNew: true,
		});
		this.incomingCallIO.on('connect', () => {
			this.incomingCallIO.emit('add_user', this.storage.secureRetrieve('audaraId'));
		});
		this.incomingCallIO.on('new_incoming_call', (data: IAudaraIncomingCall) => {
			const currentCallId = this.route.snapshot.queryParamMap.get('incomingCallId');
			const isScreenPopWindow = Boolean(currentCallId); // if its there
			if (isScreenPopWindow && data.uniqueId === currentCallId) {
				this.currentCall.next(data); // dispatch component should be listening
				return;
			}

			const timestamp = this.storage.retrieve(`timestamp-incoming-call-${data.uniqueId}`);
			const maxAllowedTime = subtractSeconds(new Date(), TIME_TO_HANDLE_DUPLICATED_CALLS_SEC);
			// Validation to show incoming call toast in all opened tabs in the browser
			if (!timestamp || isAfterDatesComparison(timestamp, maxAllowedTime)) {
				if (!timestamp) {
					this.storage.store(`timestamp-incoming-call-${data.uniqueId}`, new Date());
				}

				const incomingCallsData = JSON.parse(this.storage.retrieve('incomingCalls') || '{}');
				this.storage.store('incomingCalls', JSON.stringify({ ...incomingCallsData, [data.uniqueId]: data }));

				this.toastr
					.show(data.uniqueId, '', {
						...this.toastr.toastrConfig,
						toastComponent: IncomingCallToastComponent,
						progressBar: true,
						timeOut: INCOMING_CALL_TOAST_TIMEOUT,
						extendedTimeOut: INCOMING_CALL_TOAST_TIMEOUT,
						positionClass: 'toast-bottom-right',
					})
					.onTap.subscribe(() => {
						window.open(`#/dispatch/open?incomingCallId=${data.uniqueId}`, '_blank');
					});
			}
		});
	}

	close() {
		this.incomingCallIO?.close();
	}
}

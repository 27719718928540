import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private auth: AuthenticationService) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (!this.auth.isTokenExpired()) {
			return true;
		}
		const qParams = [];
		if (Object.keys(route.queryParams).length) {
			Object.keys(route.queryParams).forEach((key) => qParams.push(`${key}=${route.queryParams[key]}`));
		}
		this.router.navigate([`/login`], { queryParams: { redirectTo: `${route.url.join('/')}?${qParams.join('&')}` } });
		return false;
	}
}

@Injectable()
export class UnAuthGuard implements CanActivate {
	constructor(private auth: AuthenticationService) {}

	canActivate(): boolean {
		return this.auth.isTokenExpired();
	}
}

// External imports
import { Component, EventEmitter, Input, Output } from '@angular/core';
// Global imports
import { Service } from 'assets/models/Service';

@Component({
	selector: 'rb-services-result',
	templateUrl: './services-result.component.html',
	styleUrls: ['./services-result.component.scss'],
})
export class ServicesResultComponent {
	@Input() services: Service[] = [];
	@Input() contacts: any[] = [];
	@Output() selectPONumber: EventEmitter<any> = new EventEmitter();

	searchService(serviceNumber: number): void {
		this.selectPONumber.emit(serviceNumber);
	}
}

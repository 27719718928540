<div class="incoming-call-toast" [style.display]="state.value === 'inactive' ? 'none' : ''">
	<a class="incoming-call-toast__close" (click)="closeToaster($event)">
		<i class="fa fa-close"></i>
	</a>
	<div class="incoming-call-toast__label">Call from:</div>
	<div *ngIf="callData?.customerContract?.customer?.full_name">
		{{ callData?.customerContract?.customer?.full_name }}
	</div>
	<div>{{ callData?.sourceNumber }}</div>
	<br />
	<div *ngIf="callData?.lexIntentDetected">
		<span class="incoming-call-toast__label">Requires assistance:</span>
		{{ callData?.requiresAssistance ? 'Sí' : 'No' }}
	</div>
	<div>
		<span class="incoming-call-toast__label">Has GeoLocation:</span>
		{{ callData?.service?.locations.situation ? 'Sí' : 'No' }}
	</div>
	<br />
	<div class="incoming-call-toast__search">
		<div class="incoming-call-toast__label">Click Here to start search</div>
		<i class="fa fa-search"></i>
	</div>
</div>
<div *ngIf="options.progressBar">
	<div class="toast-progress" [style.width]="width + '%'"></div>
</div>

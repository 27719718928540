import { Branch, getAlpha2Code } from 'assets/models/Branch';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { SITUATIONS } from 'modules/shared/util/situations';
import { VehicleWeightOption, VehicleWeightOptionLite } from 'assets/models/enums/VehicleWeightOption';
import { Driver } from 'assets/models/Driver';
import { Division } from 'assets/constants/enums';
import { GlobalStateService } from 'modules/core/services/state/global-state';
import * as Sentry from '@sentry/angular-ivy';

export const handleError = (err, message) => {
	console.error(message, err);
	Sentry.captureException(err);
};

export function sortByName(item) {
	return item.sort((a: any, b: any) => a.name.localeCompare(b.name));
}

/**
 * It takes a phone number and a branch, and returns a formatted phone number
 * @param {string} phone - The phone number to format.
 * @param {Branch} branch - The branch of the phone number.
 * @returns A function that takes two arguments, phone and branch, and returns a string.
 */
export function formatPhoneNumber(phone: string, branch: Branch) {
	if (!phone) phone = '';
	if (isValidPhoneNumber(phone, getAlpha2Code(branch))) {
		const parsedPhoneNumber = parsePhoneNumber(phone, getAlpha2Code(branch));
		return parsedPhoneNumber.formatNational();
	}
	return phone;
}

/**
 * Sanitize phone number
 * IE: (787) 1211 1112 -> 78712111112
 */
export function sanitizePhoneNumber(phoneNumber: string): string {
	return phoneNumber.replace(/\D/g, '');
}

/**
 * If the situation is not a tow, return the lite version of the vehicle weight options, otherwise
 * return the full version
 * @param {string} situation - string - the situation that the user has selected
 * @returns the VehicleWeightOptionLite or VehicleWeightOption component.
 */
export function weightOptionItems(situation: string) {
	const towWeightOptions = [
		SITUATIONS.TOW_BREAKDOWN,
		SITUATIONS.TOW_COLLISION,
		SITUATIONS.TOW_COLLISSION,
		SITUATIONS.TOW_EXTRACTION,
		SITUATIONS.TOW_TIRE,
	];
	if (!towWeightOptions.includes(situation)) return VehicleWeightOptionLite;
	else return VehicleWeightOption;
}

/**
 * Check if parameter has a numeric value, that means any number will return true, including 0
 * @param value the value to validate
 * @returns true if the value is a number
 */
export function numValueExist(value: any) {
	return typeof value === 'number';
}

/**
 * Filters drivers based on their division and branch.
 *
 * Checks if a driver's branch matches the current branch, and if the driver has any skills
 * matching the given division. If both conditions are met, the driver is considered for inclusion in the
 * filtered list.
 */
export function filterDriversByDivision(
	driver: Partial<Driver>,
	division: Division,
	globalState: GlobalStateService
): boolean {
	// Se obtienen los drivers sólo del branch actual
	if (driver.branch === globalState.getBranch()) {
		// Si el driver tiene algún skill que sea de tipo home, se agrega al filtro dropdown de drivers
		return driver.skills?.some((skill) => {
			const serviceType = globalState.getServiceTypeByName(skill);
			return serviceType?.type === division;
		});
	}
}

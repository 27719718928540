// External imports
import { AgmCoreModule } from '@agm/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { PlaceholderModule } from 'carbon-components-angular';

// Global imports
import { windowProvider, WindowToken } from 'app/helpers/windows.helpers';
import { ThemeModule } from 'app/modules/theme/theme.module';
import { AuthGuard, UnAuthGuard } from 'modules/core/guards/auth';
import { HttpService } from 'modules/core/services/api/http.service';
import { AuthenticationService } from 'modules/core/services/auth';
import { NavbarComponent } from 'modules/shared/menu/navbar.component';

// Local imports
import { AppRoutingModule } from './routing';
import { RootComponent } from './screens/root-component';
import { LoadIndicatorComponent } from './screens/root-component/components/load-indicator/load-indicator.component';
import { MigrationFileApiService } from './services/api/migration-file.service';
import { IncomingCallSocketService } from './services/sockets/incoming-call-socket.service';
import { environment } from 'environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	declarations: [RootComponent, NavbarComponent, LoadIndicatorComponent],
	imports: [
		FormsModule,
		BrowserAnimationsModule,
		BrowserModule,
		MatProgressSpinnerModule,
		HttpClientModule,
		ToastrModule.forRoot(),
		NgxWebstorageModule.forRoot(),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBVnW1Z3yVC1_eYaoOgl0rqkARuahuJV04',
			libraries: ['places', 'visualization', 'drawing'],
		}),
		AppRoutingModule,
		ThemeModule,
		TranslateModule.forRoot({
			defaultLanguage: 'es',
			loader: {
				deps: [HttpClient],
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
			},
			isolate: true,
		}),
		PlaceholderModule,
	],
	providers: [
		AuthGuard,
		UnAuthGuard,
		AuthenticationService,
		HttpService,
		IncomingCallSocketService,
		MigrationFileApiService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({ logErrors: !environment.production }),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{ provide: WindowToken, useValue: windowProvider },
	],
	bootstrap: [RootComponent],
})
export class AppModule {}

// External imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MigrationFile } from 'assets/models/MigrationFile';

// Global imports
import { environment } from 'environments/environment';

// Local imports
import { AuthenticationService } from '../../auth';
import { ApiService } from '../base.service';
import { PaginatedMigrationFiles, ResponseMigratedServices } from './type';

@Injectable({
	providedIn: 'root',
})
export class MigrationFileApiService extends ApiService {
	private routePrefix: string = 'api/v1';

	constructor(protected http: HttpClient, protected auth: AuthenticationService) {
		super(http, auth, environment.apiLink);
	}

	public async getPaginated(page: number = 1): Promise<PaginatedMigrationFiles> {
		const limit = 10;
		const response = await this.get(`${this.routePrefix}/migration-files?limit=${limit}&page=${page}`);
		return response as unknown as PaginatedMigrationFiles;
	}

	public async getMigrationFile(migrationFileId: string): Promise<MigrationFile> {
		const response = await this.get(`${this.routePrefix}/migration-files/${migrationFileId}`);
		return response as unknown as MigrationFile;
	}

	public async getMigratedServices(migrationFileId: string): Promise<ResponseMigratedServices> {
		const response = await this.get(`${this.routePrefix}/migration-files/${migrationFileId}/migrated-services`);
		return response as unknown as ResponseMigratedServices;
	}
}

<div class="services-container e2e-global-search-container">
	<div class="titles">
		<span>Contacts</span>
	</div>
	<div class="row no-gutters contacts">
		<div class="list row no-gutters" *ngIf="contacts.length > 0">
			<div class="col-2">
				<span>Name</span>
			</div>
			<div class="col-2">
				<span>Phone</span>
			</div>
			<div class="col-2">
				<span>Account Name</span>
			</div>
			<div class="col-2">
				<span>Make and model</span>
			</div>
			<div class="col-1">
				<span>Year</span>
			</div>
			<div class="col-2">
				<span>Pet Name</span>
			</div>
			<div class="col-1">
				<span>Status</span>
			</div>
		</div>
		<div
			class="list row no-gutters list_body"
			*ngFor="let contact of contacts; let i = index"
			[ngClass]="i % 2 === 0 ? 'grid2' : 'grid1'"
		>
			<div class="col-2">
				<span>{{ contact?.customer?.full_name }}</span>
			</div>
			<div class="col-2">
				<span>{{ contact?.customer?.phone_number1 }}</span>
			</div>
			<div class="col-2">
				<span>{{ contact?.account?.name }}</span>
			</div>
			<div class="col-2">
				<span>{{ contact?.vehicle?.make }} {{ contact?.vehicle?.model }}</span>
			</div>
			<div class="col-1">
				<span>{{ contact?.vehicle?.year }}</span>
			</div>
			<div class="col-2">
				<span>{{ contact?.extras?.petName }}</span>
			</div>
			<div class="col-1">
				<span>{{ contact.status }}</span>
			</div>
		</div>
		<div *ngIf="contacts.length === 0" class="col-12 text-center not-found">No contacts found</div>
	</div>
	<div class="titles">
		<span>Services</span>
	</div>
	<div class="row no-gutters services">
		<div class="list row no-gutters" *ngIf="services.length > 0">
			<div class="col-1">
				<span>PO Number</span>
			</div>
			<div class="col-2">
				<span>Account</span>
			</div>
			<div class="col-2">
				<span>Status</span>
			</div>
			<div class="col-2">
				<span>Date</span>
			</div>
			<div class="col-2">
				<span>Service Type</span>
			</div>
			<div class="col-1">
				<span>Plate</span>
			</div>
			<div class="col-2">
				<span>Pet Name</span>
			</div>
		</div>
		<div *ngIf="services.length === 0" class="col-12 text-center not-found">No services found</div>
		<div
			class="list row no-gutters list_body"
			*ngFor="let service of services; let i = index"
			[ngClass]="i % 2 === 0 ? 'grid2' : 'grid1'"
		>
			<div class="col-1 column_active e2e-global-search-po-number" (click)="searchService(service.serviceNumber)">
				<span>{{ service.serviceNumber }}</span>
			</div>
			<div class="col-2">
				<span>{{ service.account }}</span>
			</div>
			<div class="col-2">
				<span>{{ service.status }}</span>
			</div>
			<div class="col-2">
				<span>{{ service.created | date : 'dd-MM-yyyy' }}</span>
			</div>
			<div class="col-2">
				<span>{{ service.situationLabel }}</span>
			</div>
			<div class="col-1">
				<span>{{ service.plate }}</span>
			</div>
			<div class="col-2">
				<span>{{ service?.petName }}</span>
			</div>
		</div>
	</div>
</div>

<nav class="row no-gutters navbar" *ngIf="showNavbar">
	<div class="col-lg-2 col-md-3 col-sm-3 col-12 text-center">
		<img src="assets/images/helioslogo.png" alt="helios_logo" class="logo" />
	</div>
	<div class="col-lg-6 col-md-5 col-sm-6 col-12">
		<div class="autocomplete">
			<input
				type="text"
				placeholder="Search"
				aria-label="Global Search"
				(keyup)="onKeyUp($event)"
				(keydown)="clearDataResults()"
				[(ngModel)]="searchText"
				(keyup.enter)="search(searchText)"
			/>
			<button class="button-search">
				<i *ngIf="!searching; else loading" class="fa fa-search unclickable"></i>
				<ng-template #loading>
					<i class="fa fa-spinner fa-spin unclickable"></i>
				</ng-template>
			</button>
			<button class="button-close" *ngIf="searchText" (click)="clearInput()">
				<i class="fa fa-close"></i>
			</button>
		</div>
		<rb-services-result
			*ngIf="showContainer"
			[services]="results.services"
			[contacts]="results.contacts"
			(selectPONumber)="searchService($event)"
		/>
	</div>
	<div class="col-lg-4 col-md-4 col-sm-3 col-12 services">
		<div class="row no-gutters">
			<div class="col-12 col-lg-6 margin-auto">
				<div
					[hidden]="totalServicesCount === 0"
					[routerLink]="['/dashboard/service_q']"
					[queryParams]="{ status: 'HoldInspection' }"
					*ngIf="agentDispatchAccess"
					class="services_container text-center"
				>
					<span>{{ totalServicesCount }} Services Waiting Dispatch</span>
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="actions">
					<ng-container *ngFor="let action of actions">
						<img
							[src]="action.urlImage"
							alt="action-img"
							class="action"
							(click)="redirect(action)"
							[attr.aria-label]="action.ariaLabel"
						/>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</nav>

enum VehicleWeightOption {
	PLATFORM = 'Plataforma',
	MEDIUM = 'Peso Medio',
	HEAVY = 'Peso Pesado',
}

enum VehicleWeightOptionLite {
	PLATFORM = 'Plataforma',
	MEDIUM = 'Peso Medio',
}
export { VehicleWeightOption, VehicleWeightOptionLite };

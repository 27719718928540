// External imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Global imports
import { Service } from 'assets/models/Service';
import { BaseService } from 'modules/core/services/api/base.service';
import { AuthenticationService } from 'modules/core/services/auth';
import { GlobalStateService } from 'modules/core/services/state/global-state';

// Local imports
import { SearchResults } from '../types';

@Injectable({
	providedIn: 'root',
})
export class SearchesService extends BaseService {
	private routePrefix: string = 'searches';
	constructor(
		protected http: HttpClient,
		protected auth: AuthenticationService,
		private globalStateService: GlobalStateService
	) {
		super(http, auth);
	}

	public async getResults(params: any): Promise<SearchResults> {
		const response = await this.get(`${this.routePrefix}/contacts-services`, params);
		const parsedResults = this.parseResults(response.data);
		return parsedResults;
	}

	private parseResults(results: SearchResults) {
		const parsedServices = results.services.map((service: Partial<Service>) => ({
			...service,
			situationLabel: this.globalStateService.getServiceTypeByName(service.situation)?.label || service.situation || '',
		}));
		return {
			...results,
			services: parsedServices,
		};
	}
}

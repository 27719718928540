// External imports
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Global imports
import { User } from 'assets/models/User';
import { EncryptedLocalStorageService } from '../storage';

// Local imports

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private logInStateSubject = new BehaviorSubject<boolean>(false);
	userLoggedInObservable = this.logInStateSubject.asObservable();

	constructor(private storage: EncryptedLocalStorageService) {
		// Subject state initialization
		this.logInStateSubject.next(!this.isTokenExpired());
		// Subject emitter
		storage.observe('expires').subscribe(() => {
			this.logInStateSubject.next(!this.isTokenExpired());
		});
	}

	storeAuth(token: String, expires: number, user: User): void {
		this.storage.secureStore('token', token);
		this.storage.store('expires', expires);
		this.storage.store('user', user);
	}

	getAuthToken(): string {
		return this.storage.secureRetrieve('token');
	}

	isTokenExpired(): boolean {
		return Date.now() > this.storage.retrieve('expires');
	}
}

import { Component, Input } from '@angular/core';

@Component({
	selector: 'rb-load-indicator',
	templateUrl: './load-indicator.component.html',
	styleUrls: ['./load-indicator.component.scss'],
})
export class LoadIndicatorComponent {
	@Input() show: boolean;
}

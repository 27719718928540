import { HttpParams } from '@angular/common/http';

/**
 * Convert an array to a comma delimited string.
 *
 * @param obj The value part of the key-value pair
 */
export const fromArrayToQueryParams = (obj: any[]): string => {
	return obj.join(',');
};

/**
 * Convert values from a JS object to a sorting querystring consumable object.
 *
 * @param key The *By part of the key-value pair
 * @param obj The *Direction part of the key-value pair
 */
const sortingToQueryParams = (key: string, obj: Record<string, any>): Record<string, any> => {
	const fieldName = Object.keys(obj)[0];
	const fieldValue = obj[fieldName];
	const params = {
		sortBy: fieldName,
		sortDirection: fieldValue,
	};
	return params;
};

/**
 * Converts values from a JS object to {@link @angular/common/http#HttpParams | HttpParams} object.
 *
 * @param paramsObj Object that contains all the querystring parameters.
 */
export const convertToQueryParams = (paramsObj: Record<string, any> = {}): HttpParams => {
	if (!paramsObj) {
		return new HttpParams();
	}

	const finalObj: Record<string, any> = {};
	Object.keys(paramsObj).forEach((key) => {
		const val = paramsObj[key];
		if (val !== null && val !== undefined) {
			if (typeof val !== 'object') {
				finalObj[key] = val;
			} else if (typeof val === 'object' && Array.isArray(val) && val.length > 0) {
				finalObj[key] = fromArrayToQueryParams(val);
			} else if (typeof val === 'object' && !Array.isArray(val)) {
				const fields = sortingToQueryParams(key, val);
				Object.keys(fields).forEach((k) => (finalObj[k] = fields[k]));
			}
		}
	});

	return new HttpParams({ fromObject: finalObj });
};

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';

import { DrapAndDropComponent } from './drap-and-drop.component';
export function childLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/theme/nav-menu/', '.json');
}

@NgModule({
	declarations: [DrapAndDropComponent, DragAndDropDirective],
	exports: [DrapAndDropComponent],
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: childLoaderFactory,
				deps: [HttpClient],
			},
			isolate: true,
		}),
	],
})
export class DrapAndDropModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseData } from '../../../../assets/models/ResponseData';
import { UserX } from '../../../../assets/models/User';
import { AuthenticationService } from '../auth';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class UserService extends BaseService {
	private routePrefix: string = 'users';

	constructor(protected http: HttpClient, protected auth: AuthenticationService) {
		super(http, auth);
	}

	public async getUsers(): Promise<ResponseData> {
		return await this.get(`${this.routePrefix}/all`);
	}

	public async getUsersByAccess(access: string): Promise<{ data: Partial<UserX>[] }> {
		return await this.get(`${this.routePrefix}/all?accesses=${access}`);
	}

	public async updateUser(user: UserX): Promise<ResponseData> {
		return await this.patch(`${this.routePrefix}/`, user);
	}

	public async createUser(user: UserX): Promise<ResponseData> {
		return await this.post(`${this.routePrefix}/`, user);
	}

	public async changeStatus(data: any): Promise<ResponseData> {
		return await this.patch(`${this.routePrefix}/changeStatus`, data);
	}

	public async forgotPassword(resetData: any): Promise<ResponseData> {
		return await this.post(`${this.routePrefix}/reset`, resetData);
	}

	public async resetPassword(resetData: any, token: string): Promise<ResponseData> {
		return await this.post(`${this.routePrefix}/resetPassword/${token}`, resetData);
	}

	public async login(loginData: any): Promise<ResponseData> {
		return this.post(`${this.routePrefix}/login`, loginData);
	}

	public async uploadUserFile(data: { user: string; file: any; type: any }): Promise<ResponseData> {
		return await this.post(`${this.routePrefix}/sUserImage`, data);
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DrapAndDropModule } from './modules/drap-and-drop/drap-and-drop.module';
import { NavMenuModule } from './modules/nav-menu/nav-menu.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, NavMenuModule, DrapAndDropModule],
	exports: [NavMenuModule, DrapAndDropModule],
})
export class ThemeModule {}

import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../../src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class EncryptedLocalStorageService {
	constructor(private storage: LocalStorageService, private session: SessionStorageService) {}
	secureRetrieve(key: string): any {
		const token = this.storage.retrieve('connect');
		const values = this.isValidToken(token);
		const value = values[key];
		return value;
	}

	retrieve(key: string): any {
		const value = this.storage.retrieve(key);
		return value;
	}

	secureStore(key: string, value: any): void {
		const token = this.storage.retrieve('connect');
		const values = this.isValidToken(token);
		values[key] = value;

		this.storage.store('connect', this.sign(values));
	}

	store(key: string, value: any): void {
		this.storage.store(key, value);
	}
	clear(key?: string): void {
		this.storage.clear(key);
	}

	observe(key: string): Observable<any> {
		return this.storage.observe(key);
	}

	isValidToken(token) {
		try {
			if (!token) return {};
			const payload = CryptoJS.AES.decrypt(token, environment.STORAGE_KEY).toString(CryptoJS.enc.Utf8);
			return JSON.parse(payload);
		} catch (err) {
			return { message: 'Failed to authenticate token.' };
		}
	}

	sign(payload: any, expires: number | null = null) {
		return CryptoJS.AES.encrypt(JSON.stringify(payload), environment.STORAGE_KEY).toString();
	}
}

// External imports
import { Injectable } from '@angular/core';

// Global imports
import { Account } from 'assets/models/Account';
import { FollowUp } from 'assets/models/FollowUp';
import { ResponseData } from 'assets/models/ResponseData';
import { Warranty } from 'assets/models/Warranty';

// Local imports
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService extends BaseService {
	private routePrefix: string = `accounts`;

	public async getAll(branch?: string): Promise<ResponseData<Account[]>> {
		const queryParam = branch ? `?branch=${branch}` : '';
		return await this.get(`${this.routePrefix}/all${queryParam}`);
	}

	public async getByName(name: string): Promise<ResponseData<Account>> {
		return await this.get(`${this.routePrefix}/getByName/${name}`);
	}

	public async getBySalesforce(salesforce: string): Promise<ResponseData<Account>> {
		return await this.get(`${this.routePrefix}/salesforce/${salesforce}`);
	}

	public async getByID(accountId: string): Promise<ResponseData<Account>> {
		return await this.get(`${this.routePrefix}/${accountId}`);
	}

	public async update(data: Account): Promise<ResponseData<Account>> {
		return await this.patch(`${this.routePrefix}/${data._id}`, data);
	}

	public async create(data: Account): Promise<ResponseData<Account>> {
		return await this.post(`${this.routePrefix}`, data);
	}

	public async getWarranty(accountId: string, serviceTypeId: string): Promise<ResponseData<Warranty>> {
		return await this.get(`${this.routePrefix}/${accountId}/warranties?serviceTypeId=${serviceTypeId}`);
	}

	public async getFollowUp(accountId: string, serviceTypeId: string): Promise<ResponseData<FollowUp>> {
		return await this.get(`${this.routePrefix}/${accountId}/followUp?serviceTypeId=${serviceTypeId}`);
	}
}

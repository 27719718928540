// External imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

// Global imports
import { AddOnType } from 'assets/models/interfaces/IServiceAddOn';
import { ResponseData } from 'assets/models/ResponseData';
import { IService, ServiceAddons, ServiceSubType } from 'assets/models/Service';
import { ServiceType } from 'assets/models/ServiceType';
import { IDashboardTablePayload } from 'modules/dashboard/dashboard-table.interface';

// Local imports
import { FollowUpServiceId, WarrantyServiceId } from 'modules/service/screens/service-container/services/types';
import { AuthenticationService } from '../auth';
import { BaseService } from './base.service';
import { PaginatedServicesResponse } from './types';

@Injectable({
	providedIn: 'root',
})
export class ServiceService extends BaseService {
	constructor(protected http: HttpClient, protected auth: AuthenticationService) {
		super(http, auth);
	}

	public finish(data: any): Promise<ResponseData> {
		return this.post(`services/finish`, data);
	}

	public finishAndNew(data: any): Promise<ResponseData> {
		return this.post(`services/finishAndNew`, data);
	}

	public visitPayment(data: any): Promise<ResponseData> {
		return this.post(`services/visitPayment`, data);
	}

	public getServices(params: any): Promise<ResponseData> {
		return this.get(`api/v1/services`, params);
	}

	public getServicesCount(params: any): Promise<ResponseData> {
		return this.get(`api/v1/services/count`, params);
	}

	public getPaginatedServices(payload): Observable<PaginatedServicesResponse> {
		return this.getAsObservable<PaginatedServicesResponse>('api/v2/services', {
			...payload,
			sortOptions: payload.sortOptions ? encodeURIComponent(JSON.stringify(payload.sortOptions)) : undefined,
			scheduleStartDate: payload.scheduleStartDate?.toISOString(),
			scheduleEndDate: payload.scheduleEndDate?.toISOString(),
			createdStartDate: payload.createdStartDate?.toISOString(),
			createdEndDate: payload.createdEndDate?.toISOString(),
			projection: JSON.stringify(payload.projection),
		});
	}

	public getContactActiveService(params: any): Promise<ResponseData> {
		return this.get('api/v1/services/contact/active-service', params);
	}

	public getServiceById(id: string): Promise<ResponseData> {
		return this.get(`services/${id}`);
	}

	public getDeclinedServices(body: any): Promise<ResponseData> {
		return this.post(`services/getDeclined`, body);
	}

	public getCancelledAndDeclinedServices(body: any): Promise<ResponseData> {
		return this.post(`services/getCancelledAndDeclined`, body);
	}

	public getByContactId(contactId: string): Promise<ResponseData<Array<IService>>> {
		return this.get(`services/getServicesForContact/${contactId}`);
	}

	public searchServices(body: any): Promise<ResponseData> {
		return this.post(`services/search`, body);
	}

	public editHold(data: any): Promise<ResponseData> {
		return this.post(`services/holdEdit`, data);
	}

	public getTypes(): Promise<ResponseData<ServiceType[]>> {
		return this.get(`services/types/all`);
	}

	public getTypesHome(): Promise<ResponseData> {
		return this.get(`services/types/home`);
	}

	public getOtherTypes(): Promise<ResponseData> {
		return this.get(`services/types/other`);
	}

	public getPrimaryTypes(): Promise<ResponseData> {
		return this.get(`services/types/primary`);
	}

	public cancelFinished(data: object): Promise<ResponseData> {
		return this.patch(`services/cancelFinished`, data);
	}

	public finishCancelled(data: object): Promise<ResponseData> {
		return this.patch(`services/finishCancelled`, data);
	}

	public dispatchNotCovered(data: object): Promise<ResponseData> {
		return this.post(`services/dispatchNotCovered`, data);
	}

	public editSurplus(data: object): Promise<ResponseData> {
		return this.patch(`services/updateSurplus`, data);
	}

	public addContactAttempt(data: object): Promise<ResponseData> {
		return this.put(`services/contactattempts`, data);
	}

	public toggleSnooze(data: any): Promise<ResponseData> {
		return this.put(`services/snooze`, data);
	}

	public clearSchedule(serviceId: string): Promise<ResponseData> {
		return this.delete(`services/schedule/${serviceId}`);
	}

	public async toggleAjusteExpress(data): Promise<ResponseData> {
		return await this.patch(`services/ajusteExpress/toggle`, { ...data });
	}

	public async togglePrepaid(serviceId: string, isPrepaid: boolean): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/prepaid`, { isPrepaid });
	}

	public async delayService(data): Promise<ResponseData> {
		return await this.patch(`services/monitor`, { ...data });
	}

	public async markAsRead(serviceId: string, fromFlag: string): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/notes`, { fromFlag });
	}

	public async hideNote(serviceId: string, noteId: string): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/notes/${noteId}`, {});
	}

	public editPayment(data: any, refundOldPayment: boolean = true): Promise<ResponseData> {
		return this.patch(`services/editPayment/?refundOldPayment=${refundOldPayment}`, data);
	}
	public uploadFiles(serviceId: string, data: any): Promise<ResponseData> {
		return this.post(`services/${serviceId}/files`, data);
	}
	public deleteFiles(serviceId: string, fileId: string): Promise<ResponseData> {
		return this.delete(`services/${serviceId}/files/${fileId}`);
	}
	public editServiceAddons(serviceId: string, data: ServiceAddons, type: AddOnType): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/addons?type=${type}`, data);
	}
	public saveServiceSubType(serviceId: string, data: ServiceSubType, type: AddOnType): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/subType?type=${type}`, data);
	}
	public confirmSubType(serviceId: string, data: ServiceSubType, type: AddOnType): Promise<ResponseData> {
		return this.put(`services/${serviceId}/subType/confirm?type=${type}`, data);
	}
	public editSubType(
		serviceId: string,
		subTypeId: string,
		data: ServiceSubType,
		type: AddOnType
	): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/subType/${subTypeId}?type=${type}`, data);
	}
	/**
	 * Delete a service subtype
	 * @param {string} serviceId - The id of the service you want to delete the subtype from.
	 * @param {string} subTypeId - The id of the subtype you want to delete
	 * @param {AddOnType} type - AddOnType - this is an enum that can be found in the AddOnType.ts file.
	 * @returns Promise<ResponseData>
	 */
	public deleteServiceSubType(serviceId: string, subTypeId: string, type: AddOnType): Promise<ResponseData> {
		return this.delete(`services/${serviceId}/subType/${subTypeId}?type=${type}`);
	}

	async getDocumentDownload(url: string, name: string, type: string) {
		let headers = new HttpHeaders();
		if (type) headers = headers.set('Accept', type);
		try {
			const resp: any = await this.http
				.get(url, {
					headers,
					responseType: 'blob' as 'json',
				})
				.toPromise();
			const blob = new Blob([resp], {
				type: `${type};charset=utf-8`,
			});
			saveAs(blob, name);
			return true;
		} catch (error) {
			return false;
		}
	}

	public async createWarranty(
		serviceId: string,
		accountId: string,
		serviceTypeId: string
	): Promise<ResponseData<WarrantyServiceId>> {
		return this.post(`services/${serviceId}/warranties`, {
			accountId: accountId,
			serviceTypeId: serviceTypeId,
		});
	}

	public async createFollowUp(
		serviceId: string,
		accountId: string,
		serviceTypeId: string
	): Promise<ResponseData<FollowUpServiceId>> {
		return this.post(`services/${serviceId}/followUp`, {
			accountId: accountId,
			serviceTypeId: serviceTypeId,
		});
	}

	public async markSurveyAsReviewed(serviceId: string): Promise<ResponseData> {
		return await this.post(`surveys/reviewed`, { serviceId });
	}
}

export enum Branch {
	ALL = 'Global',
	CO = 'Colombia',
	CR = 'Costa Rica',
	MX = 'Mexico',
	PA = 'Panama',
	PR = 'Puerto Rico',
}

export const validBranches = [Branch.CO, Branch.CR, Branch.MX, Branch.PA, Branch.PR];

export enum Alpha2Code {
	CO = 'CO',
	CR = 'CR',
	MX = 'MX',
	PA = 'PA',
	PR = 'PR',
}

export const Branches = Object.keys(Branch)
	.filter((key) => key !== 'ALL')
	.map((key) => Branch[key]);

export const getAlpha2Code = (branch: Branch) => {
	switch (branch) {
		case Branch.CO:
			return Alpha2Code.CO;
		case Branch.CR:
			return Alpha2Code.CR;
		case Branch.MX:
			return Alpha2Code.MX;
		case Branch.PA:
			return Alpha2Code.PA;
		case Branch.PR:
			return Alpha2Code.PR;

		default:
			return null;
	}
};

export const getBranchCurrency = (branch: Branch) => {
	switch (branch) {
		case Branch.CO:
			return 'COP';
		case Branch.CR:
			return 'CRC';
		case Branch.MX:
			return 'MXN';
		case Branch.PA:
			return 'USD';
		case Branch.PR:
			return 'USD';
		default:
			return 'USD';
	}
};

/**
 * It returns an array of one country if the branch provided is a valid one
 * or return an array of all allowed countries in lowercase (cr, co, pr, pa).
 * (Method used to initialize the autocomplete adresses for google maps.)
 */
export const getAlpha2CodeByBranch = (branch: Branch): Array<String> => {
	const country = getAlpha2Code(branch);
	if (!country) return [Alpha2Code.CO, Alpha2Code.CR, Alpha2Code.PA, Alpha2Code.PR, Alpha2Code.MX];
	return [country];
};

/**
 * Telephone max number of characters by country
 */
const telephoneMaxNumberLengths = {
	Colombia: 10,
	'Costa Rica': 8,
	Mexico: 10,
	Panama: 8,
	'Puerto Rico': 10,
};

export const getTelephoneMaxNumberLengthByBranch = (branch: Branch): number => {
	return telephoneMaxNumberLengths[branch];
};

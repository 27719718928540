import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GlobalStateService } from '../services/state/global-state';

@Injectable({
	providedIn: 'root',
})
export class GlobalStateResolver implements Resolve<boolean> {
	constructor(private globalStateService: GlobalStateService) {}

	async resolve() {
		await this.globalStateService.initState();
		return true;
	}
}

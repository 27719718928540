import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Driver, DriverStatusType } from '../../../../assets/models/Driver';
import { IProvider } from '../../../../assets/models/Provider';
import { ResponseData } from '../../../../assets/models/ResponseData';
import { AuthenticationService } from '../auth';
import { BaseService } from './base.service';

const CANCELLATION_REASON_PARAM = 'source=helios-web';
@Injectable({
	providedIn: 'root',
})
export class ProviderService extends BaseService {
	constructor(protected http: HttpClient, protected auth: AuthenticationService) {
		super(http, auth);
	}

	public getProviders(projection?): Observable<IProvider[]> {
		const params = projection ? `?projection=${JSON.stringify(projection)}` : null;
		return this.getAsObservable(`providers/all${params || ''}`).pipe(
			map((response: ResponseData<IProvider[]>) => response.data)
		);
	}

	public async getProviderById(providerId: string, populate?: boolean): Promise<ResponseData<IProvider>> {
		const params = populate ? '?populate=true' : '';
		return await this.get(`providers/getById/${providerId}${params}`);
	}

	public async createProvider(provider: IProvider): Promise<ResponseData> {
		return await this.post(`providers/`, provider);
	}

	public async updateProvider(provider: IProvider): Promise<ResponseData> {
		return await this.patch(`providers/`, provider);
	}

	public async changeStatus(data: any): Promise<ResponseData> {
		return await this.patch(`providers/changeStatus`, data);
	}

	// Drivers

	public async getDriversByProviderId(providerId: string, status?: DriverStatusType): Promise<ResponseData<Driver[]>> {
		const params = status ? `?status=${status}` : '';
		return await this.get(`providers/drivers/driversByProviderId/${providerId}${params}`);
	}
	public getDriversByVendorId(providerId: string): Observable<Driver[]> {
		return this.getAsObservable(`providers/sf/${providerId}/drivers`).pipe(
			map((response: ResponseData<Driver[]>) => response.data)
		);
	}

	public async listDrivers(): Promise<Partial<Driver>[]> {
		return await this.get<Partial<Driver>[]>(`drivers`);
	}

	public async getDriver(_id: string) {
		return await this.get(`providers/drivers/driverById/${_id}?populate=true`);
	}

	public async createDriver(driver: Driver): Promise<ResponseData> {
		return await this.post(`providers/drivers`, driver);
	}

	public async updateDriver(driver: Driver): Promise<ResponseData> {
		return await this.patch(`providers/drivers`, driver);
	}

	public async changeDriverStatus(data: any): Promise<ResponseData> {
		return await this.patch(`providers/drivers/changeStatus`, data);
	}
	// Upload Provider
	public async uploadProviderFile(data: { driver: string; file: any; type: any }): Promise<ResponseData> {
		return await this.post(`providers/drivers/sDriverImage`, data);
	}

	public async uploadVehicleFile(data: { driver: string; file: any; type: any }): Promise<ResponseData> {
		return await this.post(`providers/drivers/sVehicleImage`, data);
	}

	public async getZone(branch: string, populate: boolean = false): Promise<any> {
		return await this.get(`utils/zones/${branch}?populate=${populate}`);
	}

	public async getCancellationReasons(): Promise<ResponseData> {
		return await this.get(`cancellation-reasons?${CANCELLATION_REASON_PARAM}`);
	}
}

import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import IAudaraIncomingCall from '../../../assets/models/interfaces/IAudaraIncomingCall';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[rb-incoming-call-toast-component]',
	styleUrls: ['./incoming-call-toast.component.scss'],
	templateUrl: './incoming-call-toast.component.html',
})
export class IncomingCallToastComponent extends Toast {
	callData: IAudaraIncomingCall;
	constructor(
		public storage: LocalStorageService,
		protected toastrService: ToastrService,
		public toastPackage: ToastPackage
	) {
		super(toastrService, toastPackage);
		const incomingCallsData = JSON.parse(this.storage.retrieve('incomingCalls') || '{}');

		this.callData = incomingCallsData[this.toastPackage.message];
	}

	closeToaster(event: MouseEvent): void {
		this.toastrService.clear(this.toastPackage.toastId);
		event.stopPropagation();
	}
}
